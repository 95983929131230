import React, { Fragment } from 'react';
import { NoteContext } from '../context';
import * as UI from '../styled';
import NoNotes from '../mainComponents/nonotes';
import NoteCard from '../mainComponents/notecard';

const renderCard = note => <NoteCard key={note.id} {...note} />;

function Notes() {
  return (
    <NoteContext.Consumer>
      {({ notes }) => (
        <Fragment>
          {notes.length > 0 ? (
            <UI.NotesList>{notes.map(renderCard)}</UI.NotesList>
          ) : (
              <NoNotes />
            )}
        </Fragment>
      )}
    </NoteContext.Consumer>
  );
}

export default Notes;
