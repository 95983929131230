
export const theme = {
  black: '#111',
  white: '#eee',
  main: '#e03522',
  fontSmall: '1rem',
  fontMedium: '1.6rem',
  fontLarge: '2rem;',
  icon: '2.4rem',
  unit: '6.8rem',
}

export function val(key) {
  function prop(obj, value) {
    if(obj[value]) {
      return obj[value];
    }
    return '';
  }
  return function getThemeProperty(props) {
    return prop(props.theme, key);
  }
}