import { addZero } from '../util';

export const getNoteDate = (eventDate, eventTimezoneOffset) => {
  const d = new Date(eventDate);
  const timeCorrection = d.getTimezoneOffset() - eventTimezoneOffset;
  d.setMinutes(d.getMinutes() + timeCorrection);

  return `${d.getFullYear()}-${d.getMonth()}-${addZero(d.getDate())} `
    + `${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
};

export const getTime = () => {
  const d = new Date();
  const timestamp = d.getTime();
  const timezoneOffset = d.getTimezoneOffset();
  return { timestamp, timezoneOffset };
};
