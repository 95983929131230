import React from 'react';

export const UserContext = React.createContext({
  user: null
});
export const NoteContext = React.createContext({
  notes: [],
  addNote: () => {},
  completeNote: () => {},
});
