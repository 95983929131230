import React from 'react';
import { Link } from 'react-router-dom';
import { Header as StyledHeader, PageTitle } from '../styled';
import Login from './login';
import { Icon } from '../components/icon';

const Header = () => (
  <StyledHeader>
    <Link to="/">
      <Icon name="home" />
    </Link>
    <PageTitle>What&#x2019;s on your mind?</PageTitle>
    <div>
      <Login />
    </div>
  </StyledHeader>
);

export default Header;
