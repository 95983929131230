import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { NoteContext } from "../context";
import NoNotes from "../mainComponents/nonotes";

import styled from "styled-components";

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

/*
  https://github.com/fullstackreact/google-maps-react/blob/master/README.md
*/

class MapContainer extends Component {
  markerRender() {
    const { notes } = this.context;
    return notes.map((note, i) => {
      if (!note.location) {
        return null;
      }
      return (
        <Marker
          key={note.id}
          name={`Marker for note ${i + 1}`}
          title={`Note ${i + 1} marker`}
          position={{
            lat: note.location.coordinates[1],
            lng: note.location.coordinates[0]
          }}
          animation={this.props.google.maps.Animation.Zn}
        />
      );
    });
  }

  latLngToAddress() {
    const { google } = this.props;
    const { notes } = this.context;
    const geocoder = new google.maps.Geocoder();
    // const latLng = { lat: parseFloat(note.lat), lng: parseFloat(note.lng) }
    return new Promise((resolve, reject) => {
      notes.map(note => {
        const latLng = {
          lat: note.location.coordinates[1],
          lng: note.location.coordinates[0]
        };
        return geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            return resolve(results);
          } else {
            return reject(new Error("Could not find the location!"));
          }
        });
      });
    });
  }

  render() {
    const { google } = this.props;
    const { notes } = this.context;

    if (notes.length === 0) {
      return <NoNotes />;
    }

    const mapStyles = { position: "relative", width: "100%", height: "100%" };
    const [initialCenterNote] = notes;

    const { lat, lng } = initialCenterNote;

    // Remove notes where we do not have a position.
    const points = notes
      .filter(({ lat, lng }) => lat && lng)
      .map(({ lat, lng }) => ({ lat, lng }));

    const bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    return (
      <MapWrapper>
        <Map
          google={google}
          zoom={14}
          style={mapStyles}
          initialCenter={{ lat, lng }}
          bounds={bounds}
        >
          {this.markerRender()}
        </Map>
      </MapWrapper>
    );
  }
}

/*
  TODO: Get our own api-key. This one is borrowed from Wes Bos Node Course.
*/
MapContainer.contextType = NoteContext;

export default GoogleApiWrapper({
  apiKey: "AIzaSyD9ycobB5RiavbXpJBo0Muz2komaqqvGv0"
})(MapContainer);
