import React from "react";
import { Switch } from "react-router-dom";
import Start from "../pages/start";
import Notes from "../pages/notes";
import MapView from "../pages/map";
import PrivateRoute from "./privateRoutes";

function Routes({ isAuthenticated }) {
  return (
    <Switch>
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path="/"
        exact
        component={Start}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path="/list"
        exact
        component={Notes}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path="/map"
        exact
        component={MapView}
      />
    </Switch>
  );
}

export default Routes;
