import styled from "styled-components";
import { val } from "./theme";

export const Footer = styled.footer`
  position: sticky;
  bottom: 0;

  background-color: ${val("main")};
  color: ${val("white")};
  text-align: center;
  line-height: ${val("unit")};
  display: grid;
  grid-template-columns: ${val("unit")} 1fr ${val("unit")};
  grid-template-rows: ${val("unit")};

  & > * {
    color: white;
  }
`;
