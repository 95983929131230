import React from "react";
import { Link } from "react-router-dom";
import { Footer as StyledFooter } from "../styled";
import { Icon } from "../components/icon";

export default function Footer() {
  return (
    <StyledFooter>
      <Link to="/map">
        <Icon name="map" />
      </Link>
      <button>
        <Icon name="plus" />
      </button>
      <Link to="/list">
        <Icon name="list" />
      </Link>
    </StyledFooter>
  );
}
