const locationError = (error) => {
  let errorMessage;
  switch (error.code) {
    case error.PERMISSION_DENIED:
      errorMessage = 'User denied the request for Geolocation.';
      break;
    case error.POSITION_UNAVAILABLE:
      errorMessage = 'Location information is unavailable.';
      break;
    case error.TIMEOUT:
      errorMessage = 'The request to get user location timed out.';
      break;
    case error.UNKNOWN_ERROR:
      errorMessage = 'An unknown error occurred.';
      break;
    default:
      errorMessage = 'Unknown error';
  }
  // x.innerHTML = error.message;
  return errorMessage;
};

const getCurrentPosition = (options = {}) => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject, options);
});

export default async function getUserPosition() {
  try {
    // const position = await getCurrentPosition();
    // return position;
    return await getCurrentPosition();
  } catch (error) {
    return locationError(error);
  }
}
