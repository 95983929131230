import React from "react";
import * as UI from "../styled";
import { getNoteDate } from "../api/time";
import Icon from "../components/icon";
import { NoteContext } from "../context";
import { IdentIcon } from "../components/identicon";

class NoteCard extends React.Component {
  constructor(props) {
    super(props);

    this.toggleCard = () =>
      this.setState(state => ({
        visible: !state.visible
      }));

    this.state = {
      visible: false
    };
  }

  render() {
    const { completeNote } = this.context;
    const { id, timestamp, timezoneOffset } = this.props;
    const { visible } = this.state;

    return (
      <React.Fragment>
        <UI.NotesItem onClick={this.toggleCard} key={id}>
          {getNoteDate(timestamp, timezoneOffset)}
          <IdentIcon id={timestamp} />
          <form onSubmit={completeNote(id)} action="complete-note">
            <button type="submit">
              <Icon name="trash" />
            </button>
          </form>
          {visible && <div>hello!</div>}
        </UI.NotesItem>
      </React.Fragment>
    );
  }
}

NoteCard.contextType = NoteContext;

export default NoteCard;
