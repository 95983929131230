import styled from 'styled-components';
import brain from '../img/icons/brain.png';
import { val } from './theme';

export const NotesList = styled.ul`
  list-style-type: none;
  padding: 20px;
  margin: 0;
  width: auto;
`;

export const NotesItem = styled.li`
  display: grid;
  grid-template-columns: 1fr ${val('unit')};
  grid-template-rows: ${val('unit')} 1fr;

  border: 1px solid hsla(0, 100%, 0%, 0.5);
  border-radius: 3px;
  box-sizing: border-box;
  // background: url('${brain}') 10px no-repeat;
  background-size: contain;
  margin: 0;
  text-align: center;
  & + & {
    margin-top: 10px;
  }
  &:hover {
    background-color: hsla(0, 100%, 0%, 0.1);
  }
`;
