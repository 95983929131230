import React from "react";
import Identicon from "identicon.js";
import { withTheme } from "styled-components";

function IdentIconInner({ id, theme }) {
  function remCalc(num) {
    return parseInt(num.replace("rem", "") * 10);
  }
  const options = {
    foreground: [0, 0, 0, 255], // rgba black
    background: [255, 255, 255, 255], // rgba white
    margin: 0.2, // 20% margin
    size: remCalc(theme.unit), // 420px square
    format: "svg" // use SVG instead of PNG
  };
  const hash = (id + id + id).toString(36);
  const data = new Identicon(hash + hash + hash, options).toString();

  const image = `data:image/svg+xml;base64,${data}`;
  return <img src={image} alt={id} />;
}

export const IdentIcon = withTheme(IdentIconInner);
