import React from "react";
import styled from "styled-components";
import { val } from "../styled/theme";

// https://fontawesome.com/v4.7.0/icons/
import "font-awesome/css/font-awesome.css";

const IconWrapper = styled.span`
  font-size: ${val("icon")};
  i {
    font-size: inherit;
  }
`;

/**
 * @link https://fontawesome.com/v4.7.0/icons/
 */
export function Icon({ name }) {
  return (
    <IconWrapper>
      <i className={`fa fa-${name}`} />
    </IconWrapper>
  );
}

export default Icon;
