import { getUser } from './user';
import { getActive, getAll, clearAll } from './notes';
import { version } from '../../package.json';


const debug = window.debug || {};


if (window.isDev()) {
  debug.appVersion = version;

  debug.viewUser = function viewUser() {
    console.table(getUser());
    return getUser();
  };

  debug.getAllNotes = getAll;

  debug.viewNotes = function viewNotes () {
    const notes = getActive();
    console.table(notes);
    return notes.length;
  };

  debug.clearNotes = clearAll;
}
