import uuid from 'uuid/v4';
import { parseJSON, sleep, byId } from '../util';
import { version } from '../index';

export const KEY = '__MENTAL_NOTE';
export const EVENT_NAME = '__MENTAL_NOTE_STORE_UPDATE';

const event = new CustomEvent(EVENT_NAME);



function saveLogs(logs) {
  localStorage.setItem(KEY, JSON.stringify(logs));
  document.dispatchEvent(event);
  return logs;
}

function getLogs() {
  return localStorage.getItem(KEY);
}

export function getAll() {
  return parseJSON(getLogs(), []);
}

export function get(id) {
  const all = getAll();
  return all.find((byId(id)));
}

export function getActive() {
  function onlyActive(item) {
    return !item.archived;
  }
  return getAll().filter(onlyActive);
}

export function archive(id) {
  const archiveById = (item) => {
    if (item.id === id) {
      return {
        ...item,
        archived: true,
      };
    }
    return item;
  };

  const logs = getActive();
  const newLogs = logs.map(archiveById);
  sleep(1000);

  return saveLogs(newLogs);
}


export async function add(item) {
  const logs = getAll() || [];

  const newItem = {
    ...item,
    version,
    id: item.id || uuid(),
    archived: false,
  };

  const newLogs = [...logs, newItem];

  return saveLogs(newLogs);
}


export function clearAll() {
  return localStorage.removeItem(KEY);
}
