import styled from "styled-components";

export const GridCenter = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

export const FlexCenter = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextCenter = styled.div`
  text-align: center;
`;
