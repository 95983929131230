export const coordsToAddress = async (google, coords) => {
   const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    return geocoder.geocode({ 'location': coords }, (results, status) => {
      if (status === 'OK') {
        console.log(results[0])
        return resolve(results[0].formatted_address);
      } else {
        return reject(new Error('Could not find address.'))
      }
    })
  })
}