import styled from 'styled-components';

export const Overlay = styled.div`
  height: ${props => props.height ? '100vh' : '0'};
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, .9);
  overflow-x: hidden;
  transition: 0.5s;
  div {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
  }
`;