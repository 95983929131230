import React from "react";
import Routes from "../routes";
import { ThemeProvider } from "styled-components";
import * as api from "../api/notes";
import Header from "./header";
import Footer from "./footer";
import { BrowserRouter as Router } from "react-router-dom";
import { Main, Layout } from "../styled";
import { NoteContext, UserContext } from "../context";
import { logout, getUser, setUser } from "../api/user";
import NoAuth from "../pages/noauth";
import { theme } from "../styled/theme";

export default class Container extends React.Component {
  constructor() {
    super();

    const currentUser = getUser();

    this.state = {
      isTouch: false,
      notes: [],
      user: currentUser
    };
  }

  componentDidMount() {
    this.handleUpdate();
    document.addEventListener(api.EVENT_NAME, this.handleUpdate);
    window.addEventListener("touchstart", this.onFirstTouch, false);
  }

  componentWillUnmount() {
    document.removeEventListener(api.EVENT_NAME, this.handleUpdate);
  }

  onFirstTouch = () => {
    this.setState({
      isTouch: true
    });
    // we only need to know once that a human touched the screen, so we can stop listening now
    window.removeEventListener("touchstart", this.onFirstTouch, false);
  };

  handleUpdate = () => {
    const notes = api.getActive();
    this.setState({
      notes
    });
  };

  completeNote = id => async e => {
    e.preventDefault();
    await api.archive(id);
    this.handleUpdate();
  };

  login = user => {
    if (user.userID && user.password) {
      const { password, ...rest } = user;
      setUser(rest);
      this.setState({ user: rest });
    } else {
      console.warn("Not logged in.", { response: user });
    }
  };

  logout = () => {
    logout();
    this.setState({ user: null });
  };

  render() {
    const { notes, user, isTouch } = this.state;

    const NoteContextValue = {
      notes,
      addNote: () => {},
      completeNote: this.completeNote
    };

    const UserContextValue = {
      user,
      isTouch,
      isAuthenticated: user !== null,
      login: this.login,
      logout: this.logout
    };

    return (
      <NoteContext.Provider value={NoteContextValue}>
        <UserContext.Provider value={UserContextValue}>
          {user === null ? (
            <NoAuth />
          ) : (
            <Router>
              <ThemeProvider theme={theme}>
                <Layout>
                  <Header />
                  <Main>
                    <Routes isAuthenticated={user !== null} />
                  </Main>
                  <Footer />
                </Layout>
              </ThemeProvider>
            </Router>
          )}
        </UserContext.Provider>
      </NoteContext.Provider>
    );
  }
}
