import styled from 'styled-components';

export const Main = styled.main`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;
