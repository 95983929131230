import styled from 'styled-components';
import { val } from './theme';

export const Header = styled.header`
  position: sticky;
  top: 0;
  background: ${val('main')};
  line-height: ${val('unit')};
  text-align: center;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${val('unit')} 1fr ${val('unit')};

  & > * {
    color: ${val('white')};
  }

  i {
    font-size: ${val('icon')};
  }
`;
