import { parseJSON } from '../util';

export const USER_SESSION_KEY = "__MENTAL_NOTE_USER";

export function isAuthenticated() {
  return localStorage.getItem(USER_SESSION_KEY) !== null;
}

export function getUser() {
  return parseJSON(localStorage.getItem(USER_SESSION_KEY), null);
}

export function setUser(res) {
  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(res));
}

export function getCurrentUserId() {
  const user = getUser() || { userID: null };
  return user.userID;
}

export function logout() {
  localStorage.removeItem(USER_SESSION_KEY);
}
