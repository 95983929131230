export function parseJSON(str, fallback = null) {
  if (str === null) {
    return fallback;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return fallback;
  }
}
