import React, { Component } from "react";
import uuid from "uuid/v4";
import { GoogleApiWrapper } from "google-maps-react";
import { getCurrentUserId } from "../api/user";
import * as api from "../api/notes";
import { getTime } from "../api/time";
import getUserPosition from "../api/location";
import * as UI from "../styled";
import { coordsToAddress } from "../api/coordsToAddress";
import PressHoldButton from "../components/pressHoldButton";
import { UserContext } from "../context";

class Start extends Component {
  constructor() {
    super();

    this.state = {
      startTime: 0,
      currentTime: 0,
      loading: false,
      position: {
        coords: {
          latitude: null,
          longitude: null
        }
      },
      showRandomImage: false,
      randomImage: ""
    };
  }

  async componentDidMount() {
    const position = await getUserPosition();
    this.setState({
      position
    });
  }

  async fetchImage() {
    const data = await fetch("https://picsum.photos/380?random")
      .then(res => res.url)
      .catch(error => error);
    return data;
  }

  start = async e => {
    const now = Date.now();

    this.setState(() => ({
      loading: true,
      startTime: now
    }));

    const image = await this.fetchImage();

    this.setState(() => ({
      randomImage: image
    }));
  };

  end = async () => {
    const userId = getCurrentUserId();
    const { latitude, longitude } = this.state.position.coords;
    const id = uuid();
    const time = getTime();
    const now = Date.now();
    let address = {
      lat: null,
      lng: null
    };
    try {
      address = await coordsToAddress(this.props.google, {
        lat: latitude,
        lng: longitude
      });
    } catch (error) {}

    await api.add({
      id,
      ...time,
      location: {
        coordinates: [longitude, latitude],
        address: address || ""
      },
      image: this.state.randomImage,
      userId,
      down: now - this.state.startTime
    });

    this.setState(() => ({
      loading: false,
      startTime: 0,
      currentTime: 0,
      showRandomImage: true
    }));
  };

  closeOverlay = () => {
    this.setState(() => ({ showRandomImage: false, randomImage: "" }));
  };

  render() {
    const { loading, showRandomImage, randomImage } = this.state;

    return (
      <>
        <UI.FlexCenter>
          <PressHoldButton
            duration="10s"
            isTouch={this.context.isTouch}
            onStart={this.start}
            onComplete={this.end}
          >
            {percent => (
              <UI.BrainButton
                style={UI.getBrainStyle({ percent })}
                loading={loading}
                type="submit"
              >
                <UI.HiddenText>Brain</UI.HiddenText>
              </UI.BrainButton>
            )}
          </PressHoldButton>
        </UI.FlexCenter>
        <UI.Overlay
          height={showRandomImage && randomImage ? "true" : undefined}
          onClick={this.closeOverlay}
        >
          <div className="overlay-content">
            <img alt="" src={randomImage} />
          </div>
        </UI.Overlay>
      </>
    );
  }
}

Start.contextType = UserContext;

export default GoogleApiWrapper({
  apiKey: "AIzaSyD9ycobB5RiavbXpJBo0Muz2komaqqvGv0"
})(Start);
