import React from "react";
import ms from "ms";

const initialState = {
  time: 0,
  startTime: 0,
  timerID: null,
  counter: 0
};

class PressHoldButton extends React.Component {
  constructor(props) {
    super(props);
    const duration = props.duration || "1s";
    this.pressHoldDuration = ms(duration);

    this.state = {
      ...initialState
    };
  }

  complete = () => {
    this.props.onComplete(this.state.counter);
    this.setState(() => ({
      ...initialState
    }));
  };

  pressingDown = e => {
    this.setState(() => ({ startTime: Date.now(), time: 0 }));
    requestAnimationFrame(this.timer);
  };

  notPressingDown = e => {
    cancelAnimationFrame(this.state.timerID);
    this.complete();
  };

  timer = () => {
    const { counter } = this.state;
    const now = Date.now();
    if (counter < this.pressHoldDuration) {
      this.setState(state => ({
        time: now - state.startTime,
        startTime: state.startTime === 0 ? now : state.startTime,
        timerID: requestAnimationFrame(this.timer),
        counter: state.counter + 1
      }));
    } else {
      this.complete();
    }
  };

  render() {
    const events = this.props.isTouch
      ? {
          onTouchStart: this.pressingDown,
          onTouchEnd: this.notPressingDown
        }
      : {
          onMouseDown: this.pressingDown,
          onMouseUp: this.notPressingDown
        };
    const percent = this.state.time / this.pressHoldDuration;
    return (
      <div {...events} style={{ userSelect: "none", touchAction: "none" }}>
        {this.props.children(percent)}
      </div>
    );
  }
}

export default PressHoldButton;
