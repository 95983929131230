import React, { Fragment, Component } from "react";
import FacebookLogin from "react-facebook-login";
import { Logout } from "../styled";
import { UserContext } from "../context";
import Icon from "../components/icon";
import { feature } from "../util";

function FacebookLoginWrapper({ login }) {
  if (!feature("facebooklogin")) {
    return null;
  }
  return (
    <FacebookLogin
      appId="633133633756621"
      autoLoad={false}
      fields={"name,email,picture"}
      callback={login}
    />
  );
}

class Login extends Component {
  constructor() {
    super();

    this.user = React.createRef();
    this.password = React.createRef();
    this.state = {};
  }

  login = e => {
    e.preventDefault();

    const { login } = this.context;

    login({
      userID: this.user.current.value,
      password: this.password.current.value
    });
  };

  render() {
    const { isAuthenticated, login, logout } = this.context;
    return (
      <UserContext.Consumer>
        {() => (
          <Fragment>
            {isAuthenticated ? (
              <Fragment>
                <Logout onClick={logout}>
                  <Icon name="sign-out" />
                </Logout>
              </Fragment>
            ) : (
              <Fragment>
                <form onSubmit={this.login}>
                  <p>
                    <label>Username</label>
                    <br />
                    <input ref={this.user} name="user" type="text" />
                  </p>
                  <p>
                    <label>Password</label>
                    <br />
                    <input
                      ref={this.password}
                      name="password"
                      type="password"
                    />
                  </p>
                  <button type="submit">Login</button>
                </form>
                <FacebookLoginWrapper login={login} />
              </Fragment>
            )}
          </Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

Login.contextType = UserContext;

export default Login;
